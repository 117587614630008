<template>
  <div>
    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="state">
        <alert-circle-icon class="alert-circle-icon" />
        <h3 v-html="$t('packetCheck.select-continue')"></h3>
      </section>
      <section class="address container">
        <div class="flex-group">
          <div>
            <h2>{{ $t("packetDetail.addressee") }}</h2>
            <h3>
              {{ packet.recipient.first_name }} {{ packet.recipient.last_name }}
            </h3>
          </div>
          <div>
            <h2>{{ $t("packetDetail.sender") }}</h2>
            <strong>{{ sender }}</strong>
          </div>
        </div>
        <div class="flex-group">
          <div v-if="packet.cod.amount_cents > 0 && !packet.cod.paid">
            <h2>{{ $t("packetDetail.pay") }}</h2>
            <strong>{{ formatCod(packet) }}</strong>
          </div>
          <div>
            <h2>{{ $t("packetDetail.point") }}</h2>
            <strong>{{ packet.shelf }}</strong>
          </div>
        </div>
      </section>
    </main>
    <footer ref="footer">
      <div class="container">
        <a
          href="#"
          class="btn btn-primary btn-green"
          @click.prevent="$emit('open-scan')"
          >{{ $t("packetCheck.continue") }}</a
        >
      </div>
    </footer>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AlertCircleIcon from "vue-material-design-icons/AlertCircle";
import { setActualFooterHeight } from "@/mixins/footer";
import { formatCod } from "@/mixins/format-cod";
import Spinner from "@/components/Spinner";

export default {
  name: "PacketCheckDetail",
  mixins: [setActualFooterHeight, formatCod],
  components: {
    AlertCircleIcon,
    Spinner
  },
  props: {
    packet: {
      type: Object,
      required: true
    },
    sender: {
      type: String,
      required: true
    }
  },

  async mounted() {
    this.setActualFooterHeight(); // Set footer height after data fetch
  },

  computed: {
    ...mapState(["loading"])
  }
};
</script>

<style lang="scss" scoped>
.state {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: $orange-red;
  color: $white;
  padding: 6px 21px;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1);

  .alert-circle-icon {
    margin-right: 8px;
    line-height: 0;
  }
}

.address {
  box-sizing: border-box;
  padding: 9px 16px 12px;
  border-bottom: 1px solid $grey;

  h2 {
    color: $light-grey;
    font-weight: 400;
  }

  h3 {
    font-size: 1rem;
  }

  strong {
    font-size: 0.813rem;
  }

  .flex-group {
    display: flex;
    margin: 8px 0 4px;

    div {
      flex: 1;
    }
  }

  .flex-align-group {
    display: flex;
    align-items: center;

    span {
      height: 20px;
      margin-right: 3px;
      color: $orange-red;
    }
  }
}

footer {
  a {
    margin-bottom: 10px;

    &.a-black:last-child {
      display: inline-block;
      margin: 16px 0 26px;
    }
  }
}
</style>
